@import 'ember-paper';

@import "bootstrap";

body {
  padding-top: 20px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url('../images/lite.png');
  background-position: center;
  background-size: cover;

  font-family: 'Fira Sans', sans-serif;
  h1,h2,h3,h4,h5 {
    font-family: 'Fira Sans', sans-serif;
  }
  h1 {
    font-weight: 700;
    color: #3f3f3f;
  }
  h2 {
    font-weight: 700;
    color: black;
  }
  h3 {
    font-weight: 700;
    color: black;
  }
  h4 {
    font-weight: 400;
    color: black;
  }
  h5 {
    font-weight: 700;
    color: black;
  }
}

.about {
  text-align: left;
}

.textcards {
  background-color: whitesmoke;
  margin-bottom: 1em;
  color: black;
  padding: 1em;
  opacity: 0.8;
}

.card {
  background-color: #e9ecf2;
  margin-bottom: 1em;
  padding: 1em;
}

.bigfont {
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 5em
}

.footer {
  bottom: 0 !important;
  width: 100% !important;
  height: 2.5rem !important;
}

.largetitle {
  font-weight: 700;
  color: white;
  font-size: 5em
}

.subtitle {
  font-weight: 700;
  color: white;
  font-size: 2em
}

html {
  overflow-y: scroll;
}

.library-item {
  min-height: 150px;
}

.intro {
  padding: 3em;
  // margin-right: 1em;

  top: 0;
  left: 0;
}

#welcome {
  color: black;
  text-shadow: #0f0f11;
  filter: grayscale(20%);
  text-align: center;
}

// #hero-image {
//   position: relative;
//   width: 100%;
//   height: 400px;
//   overflow: hidden;
//   background: lightblue;
// }

.hero-image {
  background-image: url('../images/mountains.jpg');
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  background-size: cover;
  padding: 3em;

  top: 0;
  left: 0;
}


